<template>
  <div>
    <div v-if="btnStyle == 0">
      <wx-open-launch-weapp
        id="launch-btn"
        :username="username"
        :path="path"
        :env-version="envVersion"
        @launch="handleLaunchFn"
        @error="handleErrorFn"
        style="width: 240px"
      >
        <script v-is="'script'" type="text/wxtag-template">
                 <style>
                     .btn {
             background: transparent;
             height: 100%;
          }
                 </style>
          <div class="btn">
             <br><br>
          </div>
        </script>
      </wx-open-launch-weapp>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import { getWxSign } from "@/api/test/index";
import { isWeixin } from "@/utils/utils.js";
var wx = require("weixin-js-sdk");
export default {
  props: {
    envVersion: {
      type: String,
      default: "release",
    },
    username: {
      type: String,
      default: "gh_7e9819af5925",
    },
    path: {
      type: String,
      default: "/pages/index/index",
    },
    btnName: {
      type: String,
      default: "打开小程序",
    },
    btnStyle: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      infor: {},
    };
  },
  mounted() {
    // 微信内
    if (isWeixin()) {
      // 微信内
      this.getSign();
    }
    wx.ready(function () {
      var btn = document.getElementById("launch-btn");
      btn.addEventListener("launch", function () {});
      //// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后
    });

    wx.error(function () {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    });
  },
  methods: {
    async getSign() {
      const res = await getWxSign({
        url: encodeURIComponent(location.href.split("#")[0]),
      });
      if (res) {
        this.infor = res;
        this.initWeChat();
      }
    },

    // 注入config
    initWeChat() {
      wx.config({
        debug: false,
        appId: this.infor.appId,
        nonceStr: this.infor.nonceStr, // 必填，生成签名的随机串
        signature: this.infor.signature, // 必填，签名
        timestamp: this.infor.timestamp, // 必填，生成签名的时间戳
        jsApiList: ["chooseImage"],
        openTagList: ["wx-open-launch-weapp"], // 微信打开小程序开放标签
      });
    },
    handleLaunchFn() {},
    handleErrorFn() {},
  },
};
</script>

<style scoped></style>
