<template>
  <div class="item-bg">
    <div class="title">{{ title }}</div>
    <img class="img" :src="iconSrc" alt="" />
    <div
      class="open-mini"
      v-if="isWeixin && isMobile && openType == 0"
      @click="buttonClick"
    >
      <div class="button button-pre">
        {{ btnTitle }}
      </div>
      <open-mini-program class="open-miniPro"></open-mini-program>
    </div>
    <div class="open-mini" @click="buttonClick" v-else>
      <div class="button button-pre">
        {{ btnTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import OpenMiniProgram from "./open-miniprogram.vue";
import { getUrlScheme } from "@/api/test/index";
import {
  isWeixin,
  isMobile,
  isOpenMiniProgram,
  isOpenWxOffAccount,
} from "@/utils/utils.js";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    iconSrc: {
      type: String,
      default: "",
    },
    btnTitle: {
      type: String,
      default: "",
    },
    openType: {
      //打开类型默认小程序
      type: String,
      default: "0",
    },
  },
  components: {
    OpenMiniProgram,
  },
  data() {
    return {
      envVersion: "release",
      urlScheme: "",
      path: "/pages/index/index",
      isWeixin: isWeixin(),
      isMobile: isMobile(),
    };
  },
  mounted() {
    // 微信内
    if (this.openType === "0") this.getUrlScheme();
  },
  methods: {
    // 微信外获取Scheme
    async getUrlScheme() {
      const params = {
        path: this.path,
      };
      const { openLink } = await getUrlScheme(params);
      if (openLink) this.urlScheme = openLink;
    },
    // 按钮点击事件
    buttonClick() {
      if (this.openType === "0") {
        if (!isOpenMiniProgram()) return;
        window.open(this.urlScheme);
      } else if (this.openType === "1") {
        if (!isOpenWxOffAccount()) return;
        // 打开公众号
        let url =
          "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzk0NzMxMjkzMQ==#wechat_redirect";
        window.open(url);
      }
    },
  },
};
</script>

<style scoped>
.item-bg {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.title {
  white-space: pre-line;
  height: 80px;
  font-size: 26px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  align-self: center;
  color: #92492a;
  line-height: 40px;
}
.img {
  margin-top: 10px;
  width: 122px;
  height: 122px;
  align-self: center;
}

.open-mini {
  position: relative;
  margin-top: 10px;
  width: 100%;
  height: 60px;
}

.button-pre {
  position: relative;
  left: 53px;
  width: 240px;
}
.open-miniPro {
  position: absolute;
  left: 53px;
  width: 240px;
  border-radius: 30px;
}
.button {
  position: absolute;
  align-self: center;
  text-align: center;
  padding: 12px 0px;
  box-sizing: border-box;
  background: #fd7d17;
  border-radius: 30px;
  font-size: 26px;
  width: 240px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;
}
</style>
