<template>
  <div :class="bgClass">
    <img
      class="bg-light"
      src="../../assets/imgs/receiveMaterial/addwx-light.png"
      alt=""
    />
    <div class="content-bg">
      <img
        class="addwx-bg-image"
        src="../../assets/imgs/receiveMaterial/success-addwx.png"
        alt=""
      />
      <div class="longPressTxt">
        长按识别二维码或截图保存后<br />打开微信识别二维码
      </div>
      <div class="qrcode-bg">
        <img
          class="qrcode"
          src="../../assets/imgs/receiveMaterial/addwx_qrcode_1.png"
          mode="aspectFit"
          alt=""
        />
      </div>
      <div class="bottom-bg">
        <open-book-item
          :title="openMiniProTitle"
          :iconSrc="miniQrcodeIcon"
          btnTitle="打开小程序"
          style="flex: 1"
        ></open-book-item>
        <open-book-item
          :title="openWxTitle"
          :iconSrc="publicQrcodeIcon"
          openType="1"
          btnTitle="打开公众号"
          style="flex: 1"
        ></open-book-item>
      </div>
    </div>
    <top-info
      class="top-info"
      bookTitleColor="#FFFFFF"
      addWxTitleColor="#FFFFFF"
      @addWxAction="addWx"
    ></top-info>
    <!-- 添加微信弹框 -->
    <van-popup class="wx-popup" v-model="showAddWx" position="center">
      <add-wx @closeAddWxAction="showAddWx = false"> </add-wx>
    </van-popup>
  </div>
</template>

<script>
import TopInfo from "./top-info.vue";
import OpenBookItem from "./openbook-item.vue";
import AddWx from "./addWx.vue";

const miniQrcodeIcon = require("../../assets/imgs/receiveMaterial/book_mini_qrcode.png");
const publicQrcodeIcon = require("../../assets/imgs/receiveMaterial/public_qrcode.png");

export default {
  components: {
    TopInfo,
    AddWx,
    OpenBookItem,
  },
  data() {
    return {
      showAddWx: false,
      miniQrcodeIcon,
      publicQrcodeIcon,
      openMiniProTitle: "精品0元课/精 \n 品图书",
      openWxTitle: "获取学习资料 \n 及最新政策",
    };
  },
  computed: {
    bgClass: function () {
      if (window.innerHeight > 737.5) {
        // 可以全屏显示完成
        if (window.innerWidth > window.innerHeight) return "bg fullVw";
        return "bg fullVh";
      }
      return "bg fullAddWx";
    },
  },
  methods: {
    /// 添加微信好友
    addWx() {
      this.showAddWx = true;
    },
  },
};
</script>

<style scoped>
.top-info {
  position: absolute;
  top: 0;
  width: 100%;
  height: 154px;
}

.bg {
  width: 100%;
  background: linear-gradient(180deg, #ea7158 0%, #fee9e3 100%);
}

.fullVw {
  height: 100vw;
}

.fullVh {
  height: 100vh;
}

.fullAddWx {
  height: 1475px;
}

.bg-light {
  position: absolute;
  top: 0;
  left: calc((100% - 750px) / 2);
  right: calc((100% - 750px) / 2);
  width: 750px;
  height: 1068px;
}
.content-bg {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}
.addwx-bg-image {
  position: absolute;
  top: 154px;
  left: calc((100% - 726px) / 2);
  height: 1227px;
  width: 726px;
}
.qrcode-bg {
  position: absolute;
  width: 318px;
  height: 318px;
  align-self: center;
  top: 561px;
  background: linear-gradient(180deg, #fee3ca 0%, #fedcc7 100%);
  border-radius: 9px;
}
.qrcode {
  margin: 14px;
  width: 290px;
  height: 290px;
  border-radius: 9px;
}
.longPressTxt {
  position: absolute;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #92492a;
  line-height: 37px;
  top: 900px;
  align-self: center;
}
.bottom-bg {
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 360px;
  left: calc((100% - 690px) / 2);
  right: calc((100% - 690px) / 2);
  width: 690px;
  top: 1002px;
}
.wx-popup {
  background-color: transparent;
  height: 100%;
  width: 100%;
}
</style>
